import { toTheme } from "@theme-ui/typography";
import fairyGatesTheme from "typography-theme-fairy-gates";
import merge from "deepmerge";

const typography = toTheme(fairyGatesTheme);

export default merge(typography, {
  breakpoints: ["48em", "64em", "85.375em", "120em", "160em"],
  initialColorModeName: "dark",
  colors: {
    text: "#f8f8f2",
    background: "#282a36",
    primary: "#ffb86c",
    secondary: "#ff79c6",
  },
});
